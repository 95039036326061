import React, { useState } from 'react';
import LoginModal from './LoginModal';
import '../styles/header.css';

const Header = ({ isDashboard, onLogin, onLogout }) => { // Adicionado onLogout
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    onLogout(); // Chama a função de logout do App.js
  };

  return (
    <>
      <header>
        <div className="hamburger" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fas fa-bars"></i>
        </div>
        <nav className={`navbar ${isNavOpen ? 'active' : ''}`}>
          <ul>
            {isDashboard ? (
              <>
                <li><a href="#">Matches</a></li>
                <li><a href="#">Moments</a></li>
                <li><a href="#">Perfil</a></li>
                <li><a href="#" onClick={handleLogout}>Sair</a></li>
              </>
            ) : (
              <>
                <li><a href="#">Produtos</a></li>
                <li><a href="#">Suporte</a></li>
                <li><a href="#">Download</a></li>
                <li><a href="#">Blog</a></li>
              </>
            )}
          </ul>
        </nav>
        <div className="social-icons">
          <a href="https://www.instagram.com/tzeloapp/" target="_blank" className="social-icon"><i className="fab fa-instagram"></i></a>
          <a href="https://www.facebook.com/tzeloapp" target="_blank" className="social-icon"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.tzelo.com" className="brand-link">!Tzélo</a>
        </div>
        {!isDashboard && (
          <button className="login-btn" onClick={() => setIsModalOpen(true)}>
            Login
          </button>
        )}
      </header>
      <LoginModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onLogin={onLogin}
      />
    </>
  );
};

export default Header;