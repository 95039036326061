import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import './styles/global.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get('/api/auth/verify', { withCredentials: true });
        if (response.data.success) {
          setIsLoggedIn(true);
          localStorage.setItem('isLoggedIn', 'true');
        } else {
          setIsLoggedIn(false);
          localStorage.setItem('isLoggedIn', 'false');
        }
      } catch (error) {
        console.error('Erro ao verificar token:', error);
        if (error.response?.status === 431) {
          console.warn('Erro 431 detectado, limpando cookies...');
          document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        }
        setIsLoggedIn(false);
        localStorage.setItem('isLoggedIn', 'false');
      } finally {
        setIsLoading(false);
      }
    };
    verifyToken();
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
    axios.post('/api/auth/logout', {}, { withCredentials: true })
      .catch((error) => console.error('Erro ao fazer logout:', error));
    document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  };

  // Enquanto está carregando, não renderiza nada (ou um esqueleto, se preferir)
  if (isLoading) {
    return null; // Ou substitua por um componente de esqueleto, se quiser
  }

  return (
    <GoogleOAuthProvider clientId="42718710130-i4fou4bae9a1pkkdhqlmakkn3o8qtt59.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Landing onLogin={handleLogin} onLogout={handleLogout} />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              isLoggedIn ? (
                <Dashboard onLogout={handleLogout} />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;