import React from 'react';
import Header from '../components/Header';
import '../styles/global.css';

const Dashboard = ({ onLogout }) => {
  return (
    <div className="dashboard">
      <Header isDashboard={true} onLogin={() => {}} onLogout={onLogout} />
      <main>
        <section className="dashboard-content">
          <h1>Bem-vindo ao Dashboard do Tzelo</h1>
          <p>Aqui você pode ver seus matches, momentos e perfil.</p>
          {/* Adicione mais conteúdo aqui conforme necessário */}
          <div className="dashboard-options">
            <button onClick={() => alert('Funcionalidade em desenvolvimento')}>
              Ver Matches
            </button>
            <button onClick={() => alert('Funcionalidade em desenvolvimento')}>
              Ver Momentos
            </button>
            <button onClick={() => alert('Funcionalidade em desenvolvimento')}>
              Editar Perfil
            </button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;