import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import '../styles/global.css';

const LoginModal = ({ isOpen, onClose, onLogin }) => {
  const [statusMessage, setStatusMessage] = useState('');

  const handleGoogleLogin = async (response) => {
    try {
      console.log('Enviando requisição para: /api/auth/google');
      const config = {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      };
      console.log('Cabeçalhos enviados:', config.headers);
      const res = await axios.post('/api/auth/google', { token: response.credential }, config);

      if (res.data.success) {
        onLogin();
        onClose();
      } else {
        throw new Error('Resposta do backend não indicou sucesso');
      }
    } catch (error) {
      console.error('Erro no login:', error);
      if (error.response?.status === 431) {
        console.warn('Erro 431 detectado, limpando cookies...');
        document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      }
      setStatusMessage('Erro: ' + (error.response?.data?.error || error.message));
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>×</span>
        <h2>Login</h2>
        <div className="login-option google-login">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={(error) => {
              console.error('Erro no Google Login:', error);
              setStatusMessage('Erro ao fazer login com Google');
            }}
            theme="filled_blue"
            size="large"
            shape="rectangular"
          />
        </div>
        <button className="login-option facebook-login" disabled title="Em breve">
          <i className="fab fa-facebook-f"></i> Entrar com Facebook
        </button>
        <button className="login-option phone-login" disabled title="Em breve">
          <i className="fas fa-phone"></i> Entrar com Celular
        </button>
        <p className="status-message">{statusMessage}</p>
      </div>
    </div>
  );
};

export default LoginModal;