import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import LoginModal from '../components/LoginModal';
import '../styles/global.css';

const Landing = ({ onLogin, onLogout }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dynamicText, setDynamicText] = useState('');
  const [showDownloadButtons, setShowDownloadButtons] = useState(true);

  useEffect(() => {
    const words = ['Match', 'Match. Talk', 'Match. Talk. Meet'];
    let wordIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    const typeEffect = () => {
      const currentWord = words[wordIndex];

      if (!isDeleting) {
        setDynamicText(currentWord.substring(0, charIndex + 1));
        charIndex++;
        if (charIndex === currentWord.length) {
          setTimeout(() => {
            isDeleting = true;
          }, 1000);
        }
      } else {
        setDynamicText(currentWord.substring(0, charIndex));
        charIndex--;
        if (charIndex < 0) {
          isDeleting = false;
          wordIndex = (wordIndex + 1) % words.length;
          charIndex = 0;
        }
      }
    };

    const interval = setInterval(typeEffect, 150);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowDownloadButtons(scrollPosition === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="content-wrapper">
      <Header setIsModalOpen={setIsModalOpen} onLogin={onLogin} onLogout={onLogout} isDashboard={false} />
      <LoginModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onLogin={onLogin} />
      <main>
        <section className="hero">
          <video autoPlay muted loop playsInline id="bg-video" onError={(e) => console.log("Erro ao carregar vídeo:", e)}>
            <source src="/videos/fundo.mp4" type="video/mp4" />
            <p>Erro: Vídeo não carregou ou navegador não suporta.</p>
          </video>
          <div className="static-text">Encontre conexões reais, converse e faça novas amizades.</div>
          <h2 id="dynamic-text">{dynamicText}</h2>
          <div className={`download-buttons ${showDownloadButtons ? '' : 'hidden'}`}>
            <a href="https://play.google.com/store/apps/details?id=com.example" target="_blank" className="google-play">
              <img src="/assets/Imagemgoogleplay.webp" alt="Baixe na Google Play" />
            </a>
            <a href="https://apps.apple.com/br/app" target="_blank" className="app-store">
              <img src="/assets/Imagemappstore.webp" alt="Baixe na App Store" />
            </a>
          </div>
        </section>
        <section className="testimonials">
          <h2>O que nossos usuários dizem</h2>
          <div className="testimonial-cards">
            <div className="card">
              <p>"Conheci pessoas incríveis no Tzelo! A interface é super fácil de usar e as conexões são reais."</p>
              <p className="author">Ana, 28</p>
            </div>
            <div className="card">
              <p>"Adorei a experiência! Fiz amigos e até encontrei alguém especial. Recomendo!"</p>
              <p className="author">Lucas, 32</p>
            </div>
            <div className="card">
              <p>"O Tzelo me ajudou a sair da rotina e conhecer pessoas novas. É incrível!"</p>
              <p className="author">Mariana, 25</p>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="footer-links">
            <div>
              <h3>Explore o Tzelo</h3>
            </div>
            <div>
              <h3>Jurídico</h3>
              <a href="#">Privacidade</a>
              <a href="#">Política de Privacidade</a>
              <a href="#">Termos de Uso</a>
              <a href="#">Política de Cookies</a>
              <a href="#">Propriedade Intelectual</a>
            </div>
            <div>
              <h3>Suporte</h3>
              <a href="#">FAQ</a>
              <a href="#">Contato</a>
              <a href="#">Código Promocional</a>
            </div>
          </div>
          <p className="footer-copyright">© 2025 !Tzélo. Todos os direitos reservados.</p>
        </footer>
      </main>
    </div>
  );
};

export default Landing;